import "./editproject.scss"
import { createProjectImages, createProjectVideos, deleteProject, deleteProjectImages, deleteProjectVideos, updateProjects } from "../../../utils/hooks/projectHooks/api";
import React, { useState, useEffect, useRef } from "react";
import useOneProject from "../../../utils/hooks/projectHooks/useOneProject";
import useOneProjectTm from "../../../utils/hooks/projectHooks/useOneProjectTm";
import {ReactComponent as Load} from '../../../assets/load.svg';
import { useRecoilState } from "recoil";
import { urlState } from "../../../utils/atoms";



const EditProject = (props) => {

    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)


    const [project] = useOneProject();
    const [project_tm] = useOneProjectTm();
    const [proj_id, setId] = useState('');
    const [url] = useRecoilState(urlState)
    const [projectHighlight, setprojectHighlight] = useState();
    const [projectHead, setprojectHead] = useState();
    const [projectDescription, setprojectDescription] = useState();
    const [projectMainImg, setProjectMainImg] = useState('');
    const [projectMainImgTemp, setProjectMainImgTemp] = useState();


    const [projectImgArray, setProjectImgArray] = useState([]);
    const [projectImgArrayTemp, setProjectImgArrayTemp] = useState([]);
    const [projectVideoArray, setProjectVideoArray] = useState([]);
    const [projectVideoArrayTemp, setProjectVideoArrayTemp] = useState([]);

    const [projectHighlightTranslated, setprojectHighlightTranslated] = useState();
    const [projectHeadTranslated, setprojectHeadTranslated] = useState();
    const [projectDescriptionTranslated, setprojectDescriptionTranslated] = useState();
    const [toggleState, setToggleState] = useState(1);

    const handleClick = () => {
        inputRef.current.click();
    };


    const toggleTab = (index) => {
        setToggleState(index);
    };

    const getActiveClass = (index, className) =>
        toggleState === index ? className : "";


    useEffect(() => {
        setId(localStorage.getItem('id'));
    }, [])



    useEffect(() => {
        setprojectHighlight(project && project.title_highlight);
        setprojectHead(project && project.title_head);
        setprojectDescription(project && project.description);
        setProjectMainImgTemp(project && url + project.project.multimedia)

    }, [project])

    useEffect(() => {
        setprojectHighlightTranslated(project_tm && project_tm.title_highlight);
        setprojectHeadTranslated(project_tm && project_tm.title_head);
        setprojectDescriptionTranslated(project_tm && project_tm.description);

    }, [project_tm])


    function showing(i_id) {
        const el = document.getElementById(i_id)
        el.style.display = "none";
    }


    function addImgToArray(e) {
        let item = Object.entries(e.target.files).map((e) =>
            URL.createObjectURL(e[1]))
        setProjectImgArray([...projectImgArray, item])
        setProjectImgArrayTemp([...projectImgArrayTemp, e.target.files[0]]);
    }

    function addVideoToArray(e) {
        let itemvideo = Object.entries(e.target.files).map((e) =>
            URL.createObjectURL(e[1]))
        setProjectVideoArray([...projectVideoArray, itemvideo])
        setProjectVideoArrayTemp([...projectVideoArrayTemp, e.target.files[0]]);
    }


    const handleEdit = async () => {
        const editProjectForm = new FormData()
        editProjectForm.append('title_highlight', projectHighlight)
        editProjectForm.append('title_head', projectHead)
        editProjectForm.append('description', projectDescription)
        editProjectForm.append('multimedia', projectMainImg)

        const projImgForm = new FormData()
        projectImgArrayTemp.forEach(item => {
            projImgForm.append('project_images', item)
        })
        projImgForm.append('default_language', 'en')

        const projVideoForm = new FormData()
        projectVideoArrayTemp.forEach(itemv => {
            projVideoForm.append('project_videos', itemv)
        })
        projVideoForm.append('default_language', 'en')

        try {
            setIsLoading(true)
            await updateProjects(proj_id, editProjectForm)
            await createProjectImages(proj_id, projImgForm)
            await createProjectVideos(proj_id, projVideoForm)
            setIsLoading(false)
            props.handleClose();
               
        }
        catch (error) {
            console.log(error)
            alert('Something went wrong');
            setIsLoading(false)
        }
    }




    const handleEditTranslate = async () => {
        const lang_code = 'tm'
        const editProjectTranslation = new FormData()
        editProjectTranslation.append('title_highlight', projectHighlightTranslated)
        editProjectTranslation.append('title_head', projectHeadTranslated)
        editProjectTranslation.append('description', projectDescriptionTranslated)
        editProjectTranslation.append('lang_code', lang_code)

        try {
            await updateProjects(proj_id, editProjectTranslation)

        } catch (error) {
            console.log(error);
        }
    }

    const handleDetele = async () => {
        try {
            await deleteProject(proj_id)
            props.handleClose();
            window.location.reload();
        }
        catch (error) {
            console.log(error)
            alert('Something went wrong');
        }

    }



    function deleteImgArray(e) {
        const s = projectImgArray.filter((item, index) => index !== e);
        setProjectImgArray(s);
        const m = projectImgArrayTemp.filter((item, index) => index !== e);
        setProjectImgArrayTemp(m);
    }

    function deleteVideoArray(e) {
        const v = projectVideoArray.filter((item, index) => index !== e);
        setProjectVideoArray(v);
    }

    function deleteFileImg(img_id) {
        deleteProjectImages(proj_id, img_id)
    }

    function deleteFileVideo(video_id) {
        deleteProjectVideos(proj_id, video_id)
    }

    return (
        <div className="popup-box">
            <div className="projectbox">
                <div className="modal-header">
                    <h2>Edit Project</h2>
                </div>
                <form onSubmit={(e) => { e.preventDefault() }} >
                    <div className="modal-body">
                        <ul className="tab-list">
                            <li className={`tabs ${getActiveClass(1, "active-tabs")}`} onClick={() => toggleTab(1)}>
                                EN
                            </li>
                            <li className={`tabs ${getActiveClass(2, "active-tabs")}`} onClick={() => toggleTab(2)}>
                                TM
                            </li>
                        </ul>
                        <div className={toggleState === 1 ? "content  active-content" : "content"}>
                         
                            <div className="projectPopCard">

                                <img className="mainImg" src={projectMainImgTemp} onClick={handleClick} />
                                <input
                                    ref={inputRef}
                                    type="file"
                                    onChange={(e) => { setProjectMainImg(e.target.files[0]); setProjectMainImgTemp(URL.createObjectURL(e.target.files[0])) }}
                                />
                                <label htmlFor="multiImg"> Add more image
                                    <input
                                        id="multiImg"
                                        type="file"
                                        disabled={projectImgArray.length === 20}
                                        className="form-control"
                                        onChange={addImgToArray}
                                        multiple />
                                </label>

                                <div className="imgEditContainer" >

                                    {project && project.project.project_images.map(function (obj, i) {
                                        return (
                                            <div className="prodImgEdit" key={i} id={obj.id}>
                                                <div className="prodImgEdit" style={{ backgroundImage: `url(${project && url + obj.image})` }} >
                                                </div>
                                                <button className='deleteImg' onClick={() => {
                                                    showing(obj.id);
                                                    deleteFileImg(obj.id)
                                                }}>
                                                    x
                                                </button>

                                            </div>
                                        )
                                    })}


                                    {projectImgArray.length > 0 &&
                                        projectImgArray.map((item, index) => {
                                            return (
                                                <div className="prodImgEdit" style={{ backgroundImage: `url(${item})` }} key={index}>
                                                    <button className='deleteImg' onClick={() => deleteImgArray(index)}> x </button>
                                                </div>
                                            );
                                        })}
                                </div>

                                <label htmlFor="multiVideo"> Add more video
                                    <input
                                        id="multiVideo"
                                        type="file"
                                        disabled={projectVideoArray.length === 20}
                                        className="form-control"
                                        onChange={addVideoToArray}
                                        multiple />
                                </label>
                                <div className="imgEditContainer">
                                    {project && project.project.project_videos.map(function (obj_v, i) {
                                        return (
                                            <div className="videoEdit" key={i} id={obj_v.id}>
                                                <div className="videoEdit" >
                                                    <video src={project && url + obj_v.video} ></video>
                                                </div>
                                                <button className='deleteVideo' onClick={() => {
                                                    showing(obj_v.id)
                                                    deleteFileVideo(obj_v.id)

                                                }}>
                                                    x
                                                </button>
                                            </div>
                                        )
                                    })}
                                    {projectVideoArray.length > 0 &&
                                        projectVideoArray.map((itemvideo, index) => {
                                            return (
                                                <div className="videoEdit" key={index}>
                                                    <div className="videoEdit" >
                                                        <video src={itemvideo}></video>
                                                    </div>
                                                    <button className='deleteVideo' onClick={() => deleteVideoArray(index)} >
                                                        x
                                                    </button>
                                                </div>
                                            );
                                        })}
                                </div>
                                <div className="headInput">
                                    <input className="projectValue" type="text" value={projectHighlight} onChange={(e) => setprojectHighlight(e.target.value)} />
                                    <input className="projectValue" type="text" value={projectHead} onChange={(e) => setprojectHead(e.target.value)} />
                                    <textarea className="projectValue" cols="10" rows="8" value={projectDescription} onChange={(e) => setprojectDescription(e.target.value)}></textarea>
                                </div>
                            </div>
                            {isLoading ?   <Load className='loading'/> : null}
                          
                        </div>
                        <div className={toggleState === 2 ? "content  active-content" : "content"}>
                            <div className="projectPopCard">
                                <div className="headInput">
                                    <input type="text" value={projectHighlightTranslated} onChange={(e) => setprojectHighlightTranslated(e.target.value)} />
                                    <input type="text" value={projectHeadTranslated} onChange={(e) => setprojectHeadTranslated(e.target.value)} />
                                    <textarea cols="10" rows="8" value={projectDescriptionTranslated} onChange={(e) => setprojectDescriptionTranslated(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                        <button className="close-icon" onClick={props.handleClose}>x</button>
                        <button className="delete-icon" onClick={handleDetele}>Delete</button>
                        <button className="save-icon" type="submit" onClick={() => { handleEdit(); handleEditTranslate() }}>Save</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default EditProject
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../assets/svg";
import useUsers from "../../utils/hooks/useUsers";
import { outToken } from "../../utils/api";
import "./navbar.scss"

const Navbar = () => {

    const [users] = useUsers();
    const navigate = useNavigate();

    const SignOut = async () => {
        localStorage.removeItem("token");
        window.location.reload();
        navigate("/");
    }

    return (
        <div className="navbar">
            <div className="wrapper">

                <div className="navItems">
                    
                    <div className="adminLog">
                        <p>{users && users.username }</p> 
                        <p>Web administrator</p>
                    </div>
                    <div onClick={SignOut}>
                        <Logout />
                    </div>

                </div>


            </div>
        </div>
    )
}

export default Navbar
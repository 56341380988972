import axios from 'axios'

const axiosInstance = axios.create({baseURL: "https://api.techbuildme.com/"})
axiosInstance.defaults.headers['authorization'] = `Bearer ${localStorage.getItem('token')}`


// LoOIN
const getToken = (form) => {
    return axiosInstance.post('authentication/admin/sign_in', form)
}

const outToken = () => {
    return axiosInstance.get('authentication/admin/sign_out')
}
//

const getUsers = () => {
    return axiosInstance.get("users/admin/me")  

}

const updateUsers = (user_id,formData) => {
    return axiosInstance.put(`users/admin/${user_id}`,formData)  

}

const getFollowers = () => {
    return axiosInstance.get("follow_us/admin/follow/all")  
}

const deleteFollowers = (id) => {
    return axiosInstance.delete(`follow_us/admin/${id}`)  
}

const getVideos = () => {
    return axiosInstance.get("main_video/admin/all")  
}

const updateVideos = (video_id,formData) => {
    return axiosInstance.put(`main_video/admin/${video_id}`,formData)  

}

export {
    getToken,
    outToken,
    getUsers,
updateUsers,
    getFollowers,
    deleteFollowers,
    getVideos,
    updateVideos
}
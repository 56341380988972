import React from 'react'
import './home.scss'
import Navbar from '../../components/Navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'

const Home = () => {
  return (
    <div>
      <Navbar/>
      <Sidebar/>
    </div>
  )
}

export default Home
import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Admin from "./components/Admin/Admin";
import Followers from "./components/Followers/Followers";
import Projects from "./components/Projects/Projects";
import Video from "./components/Video/Video";
function App() {

const [isLoggedin, setIsLoggedIn] = useState(true);

useEffect(() => {
  if (localStorage.getItem("token")) {
    setIsLoggedIn(true);
  } else {
    setIsLoggedIn(false);
  }
}, []);
	return (
		<div className="App">
			<BrowserRouter>
				{isLoggedin ? (
					<div>
            <Routes>
						<Route index path="*" element={<Home />} />
					</Routes>
          <Routes>
          <Route path="admin" element={<Admin />} />
          <Route path="followers" element={<Followers/>} />
          <Route path="projects" element={<Projects/>} />
          <Route path="video" element={<Video/>} />
          </Routes>
          </div>

				) : (
					<Routes>
						<Route path="*" element={<Login/>} />
					</Routes>
				)}
			</BrowserRouter>
		</div>
	);
}

export default App;


import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { getToken } from '../../utils/api';
import "./style.scss"
const Login = () => {

  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const navigate = useNavigate();

  const handleSent = async () => {
    try {
      const response = await getToken(new URLSearchParams({
        username: username,
        password: password
      }))
      localStorage.setItem('token', response.data.access_token)
      window.location.reload();
    }

    catch (error) {
      console.log(error)
      alert('Wrong username or password!');
      window.location.reload();
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      alert("Login expired!")
      localStorage.removeItem("token");
      window.location.reload();       
      navigate("/");
    },1800000)
  },[])
  return (
    <div className='loginPage'>
      <div className="loginBox">
        
        <div className="loginCard">
          <p>Welcome Admin!</p>
          <form onSubmit={(e) => { e.preventDefault() }}>
            <div className="inputBox">
              <label htmlFor="username">Username</label>
              <input name='username' type="text" onChange={(e) => { setUsername(e.target.value) }} />
              <label htmlFor="password">Password</label>
              <input name='password' type="password" onChange={(e) => { setPassword(e.target.value) }} />
              <button type="submit" onClick={handleSent}>Log In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
import { useEffect, useState } from 'react'
import { getOneProjectTm } from './api'

const useOneProjectTm = () => {
  
    const [projecttm, setOneProjectTm] = useState()

    const getData = async(setFunc) => {
        const response = await getOneProjectTm()

        setFunc(response.data)
       
    }

    useEffect(()=>{
        getData(setOneProjectTm)
      
    }, [])

    return [projecttm]
}

export default useOneProjectTm
import './followers.scss'
import { Copy } from '../../assets/svg';
import useFollowers from '../../utils/hooks/useFollowers';
import { deleteFollowers } from '../../utils/api';
import React, { useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const Followers = () => {

    const [state, setState] = useState({
        value: '',
        copied: false,
    })

const [followers] =useFollowers();

const handleDelete = async (id) =>{
    try{
        await deleteFollowers(id)
       
        window.location.reload();
    }
    catch (error) {
        console.log(error)
        alert('Something went wrong');
    }
}

const renderFollowerCard = useMemo(() => {
    return (
        followers && followers.length > 0 ?
        followers && followers.map((follower, ind) => {
       ind++;
          return (
            <tr key={ind}>
            <td>{ind}</td>
            <td><input type="text" value={follower.email} readOnly 
            onChange={({ target: { value } }) => setState({ value, copied: false })} /></td>          
            <td>
            <CopyToClipboard text={follower.email}
                onCopy={() => setState({ copied: true })}>              
                <button ><Copy/></button>               
            </CopyToClipboard>
            </td>
            <td> <button  onClick={() => {
                handleDelete(follower.id) 
              }} className='followerDelete'>Delete</button></td>
        </tr>
          )
        })
        :
        "No follower uploaded!"
    )
  }, [followers])
    return (
        <div className='container'>
            <h1>Followers</h1>
             <div className="followersSection">
                <table className='table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Follower</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderFollowerCard}                        
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Followers
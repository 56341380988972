import { useEffect, useState } from 'react'
import { getUsers } from '../api'

const useUsers = () => {
  
    const [users, setUsers] = useState()

    const getData = async(setFunc) => {
        const response = await getUsers()

        setFunc(response.data)
        // console.log(response.data)
    }

    useEffect(()=>{
        getData(setUsers)
        // console.log(users)
    }, [])

    return [users]
}

export default useUsers
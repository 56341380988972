import "./video.scss"
import React, { useMemo , useState} from 'react'
import { useRecoilState } from 'recoil';
import useVideos from '../../utils/hooks/useVideo';
import VideoEdit from './VideoEdit/VideoEdit';
import { urlState } from '../../utils/atoms';


const Video = () => {

  const [isEdit, setIsEDit] = useState(false);
  const toggleEdit = () => {
    setIsEDit(!isEdit);
  }
  const [videos] = useVideos();
  const [url] = useRecoilState(urlState);
  const setId = (id) => {
   console.log(id);
   localStorage.setItem('id',id)
  }
  
  const renderVideoCard = useMemo(() => {
    return (
      videos && videos.length > 0 ?
      videos.map((video, ind) => {
        return (
          <div className="videoCard" key={ind}>
            <video width="400" height="240" controls>
              <source src={url + video.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button onClick={() =>{
              toggleEdit(); 
              setId(video.id)}}>Edit</button>
          </div>
        )
      })
      :
      "No video uploded!"
    )
  }, [videos])

  return (
    
    <div className='container'>
     
      <h1>Video</h1>

      <div className="videoSection">
        {renderVideoCard}
      </div>
      {isEdit && <VideoEdit handleClose={toggleEdit} />}


    </div>
  )
}

export default Video
import React, { useState } from 'react'
import useUsers from '../../utils/hooks/useUsers';
import { updateUsers } from '../../utils/api';
import "./admin.scss"
const Admin = () => {

    const [users] = useUsers();
    const userId = users && users.id;
    const [username, setUsername] = useState('');
    const [old_password, setOldPassword] = useState('');
    const [new_password, setNewPassword] = useState('');
    const [new_password_confirm, setNewPasswordConfirm] = useState('');

    const handleUpdate = async () => {
        const userForm = new FormData()
        userForm.append("username", username)
        userForm.append("old_password", old_password)
        userForm.append("new_password", new_password)
        userForm.append("new_password_confirm", new_password_confirm)

        try {
            await updateUsers(userId, userForm)
            window.location.reload();

        }
        catch (error) {
            console.log(error)
            alert('Something went wrong');

        }


    }

    return (

        <div className='container'>
            <h1>Admin Settings</h1>
            <form onSubmit={(e) => { e.preventDefault() }}>
                <div className="adminSection">
                    <p className='passwordRules'>* Incase you change password: <br />
                        - 8 characters;<br />
                        - uppercase and lowercase;<br />
                        - include symbols ($,&,%..), numbers and letters<br />
                    </p>
                    <div className='inputBox'>
                        <input type="text" placeholder='new username' onChange={(e) => { setUsername(e.target.value) }} />
                        <input type="password" placeholder='old password' onChange={(e) => { setOldPassword(e.target.value) }} />
                        <input type="password" placeholder='new password' onChange={(e) => { setNewPassword(e.target.value) }} />
                        <input type="password" placeholder='confirm password' onChange={(e) => { setNewPasswordConfirm(e.target.value) }} />
                    </div>
                    <button type="submit" onClick={handleUpdate}>Update</button>
                </div>
            </form>
        </div>


    )
}

export default Admin
import { useEffect, useState } from 'react'
import { getFollowers } from '../api'

const useFollowers = () => {
  
    const [followers, setFollowers] = useState()

    const getData = async(setFunc) => {
        const response = await getFollowers()

        setFunc(response.data)
        
    }

    useEffect(()=>{
        getData(setFollowers)
        
    }, [])

    return [followers]
}

export default useFollowers
import './addproject.scss'
import { useState, useRef, } from 'react';
import { useNavigate } from "react-router-dom";
import { createProjects, createTranslated } from '../../../utils/hooks/projectHooks/api';
import {ReactComponent as Load} from '../../../assets/load.svg';



const AddProject = (props) => {

    const [toggleState, setToggleState] = useState(1);
    const [projectHead, setProjectHead] = useState('');
    const [projectHighlight, setProjectHighlight] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectFile, setprojectFile] = useState('');
    const [projectMulti, setprojectMulti] = useState([]);
    const [projectVideo, setprojectVideo] = useState([]);
    const [translationHead, setTranslationHead] = useState('');
    const [translationHighlight, setTranslationHighlight] = useState('');
    const [translationDescription, setTranslationDescription] = useState('');

    const [projectId, setProjectId] = useState()
    let proj = ''
    const navigate = useNavigate()
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const [file, setFile] = useState([]);
    const [oneImg, setoneImg] = useState([]);
    const [video, setVideo] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    function uploadSingleVideo(e) {
        let VideoArray = Object.entries(e.target.files).map((e) =>
            URL.createObjectURL(e[1])
        );
        setVideo([...video, ...VideoArray]);
        setprojectVideo([...projectVideo, e.target.files[0]])
        console.log(projectVideo);
    }

    function uploadSingleFile(e) {
        let ImagesArray = Object.entries(e.target.files).map((e) =>
            URL.createObjectURL(e[1])
        );
        setFile([...file, ...ImagesArray]);
        setprojectMulti([...projectMulti, e.target.files[0]])
    }

    function deleteFile(e) {
        const s = file.filter((item, index) => index !== e);
        setFile(s);
        const p = projectMulti.filter((item, index) => index !== e);
        setprojectMulti(p);
    }

    function deleteVideo(e) {
        const s = video.filter((vitem, vindex) => vindex !== e);
        setVideo(s);
        const v = video.filter((vitem, vindex) => vindex !== e);
        setprojectVideo(v);
    }

    const getActiveClass = (index, className) =>
        toggleState === index ? className : "";

    const handleSaveProject = async () => {
        const projectForm = new FormData()

        projectForm.append('title_highlight', projectHighlight)
        projectForm.append('title_head', projectHead)
        projectForm.append('description', projectDescription)
        projectForm.append('multimedia', projectFile)
        projectMulti.forEach(item => {
            projectForm.append('project_images', item)
        })

        projectVideo.forEach(elem => {
            projectForm.append('project_videos', elem)
        })
        try {
            setIsLoading(true)
            const response = await createProjects(projectForm)            
            proj = response.data.project.id
            setProjectId(proj)           
            toggleTab(2);

        } catch (error) {
            console.log(error)
        }
    }

    const handleSaveTranslation = async () => {
        const translationForm = new FormData()
        translationForm.append('title_highlight', translationHighlight)
        translationForm.append('title_head', translationHead)
        translationForm.append('description', translationDescription)
        translationForm.append('origin_elem_id', projectId)
        translationForm.append('lang_code', 'tm')

        try {
            await createTranslated(translationForm)
            
            props.handleClose();
            window.location.reload();

        } catch (error) {
            console.log(error)
          
        }
    }
    return (
        <div className="addProject">
            <div className="projectbox">
                <div className="modal-header">
                    <h2>Add Project</h2>
                </div>

                <form onSubmit={(e) => { e.preventDefault() }}>
                    <div className="modal-body">
                        <ul className="tab-list">
                            <li className={`tabs ${getActiveClass(1, "active-tabs")}`}>
                                EN
                            </li>
                            <li className={`tabs ${getActiveClass(2, "active-tabs")}`}>
                                TM
                            </li>
                        </ul>
                        <button className="close-icon" onClick={props.handleClose}>x</button>

                        <div className={toggleState === 1 ? "content  active-content" : "content"}>
                            <div className="projectPopCard">
                                <img className='coverImg' src={oneImg} alt="" />
                                <label htmlFor="projectTag">Add cover image
                                    <input id="projectTag" multiple type="file" accept="image/png, image/jpg, image/svg, image/jpeg" onChange={(e) => { setprojectFile(e.target.files[0]); setoneImg(URL.createObjectURL(e.target.files[0])) }} />
                                </label>
                                <div className='imgForm' onSubmit={(e) => { e.preventDefault() }}>
                                    <div className="form-group preview">
                                        {file.length > 0 &&
                                            file.map((item, index) => {
                                                return (
                                                    <div key={item} className="uploadImg" style={{ backgroundImage: `url(${item})` }}>
                                                        {/* <img src={item} alt="" /> */}
                                                        <button type="button" onClick={() => deleteFile(index)}>
                                                            x
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="multiImg" > Add description images
                                            <input
                                                id="multiImg"
                                                type="file"
                                                disabled={file.length === 20}
                                                className="form-control"
                                                onChange={uploadSingleFile}
                                                multiple />
                                        </label>
                                    </div>
                                    <div className="form-group preview">
                                        {video.length > 0 &&
                                            video.map((vitem, vindex) => {
                                                return (
                                                    <div key={vitem} className="uploadVideo" >
                                                        <video src={vitem} />
                                                        <button type="button" onClick={() => deleteVideo(vindex)}>
                                                            x
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="multiVideo"> Add description videos
                                            <input
                                                id="multiVideo"
                                                type="file"
                                                disabled={video.length === 20}
                                                className="form-control"
                                                onChange={uploadSingleVideo}
                                                multiple />
                                        </label>
                                    </div>
                                </div>

                                <div className="headInput">
                                    <input type="text" placeholder="Heading highlight" onChange={(e) => { setProjectHighlight(e.target.value) }} required />
                                    <input type="text" placeholder="Heading" onChange={(e) => { setProjectHead(e.target.value) }} required />
                                    <textarea cols="10" rows="8" placeholder='Description' onChange={(e) => { setProjectDescription(e.target.value) }} required></textarea>
                                </div>
                            </div>
                          {isLoading ?   <Load className='loading'/> : null}
                            <button type='submit' className="next-btn" onClick={handleSaveProject}>Next</button>
                        </div>

                        <div className={toggleState === 2 ? "content  active-content" : "content"}>
                            <div className="projectPopCard">
                                <div className="headInput">
                                    <input type="text" placeholder="Baş sözi" required onChange={(e) => { setTranslationHighlight(e.target.value) }} />
                                    <input type="text" placeholder="Taslama ady" required onChange={(e) => { setTranslationHead(e.target.value) }} />
                                    <textarea cols="10" rows="8" placeholder='Taslama beýany' required onChange={(e) => { setTranslationDescription(e.target.value) }}></textarea>
                                </div>
                            </div>
                            {/* <Snackbar ref={snackbarRef} message="Language added!!" type={SnackbarType.success} /> */}
                            <button className="next-btn" type='submit' onClick={handleSaveTranslation}>Save</button>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    )
}

export default AddProject
